import React, { useEffect, useState } from 'react'
import '../index.css'
import { Layout } from 'antd'
import SidebarComponent from '../components/SidebarComponent'
import HeaderMenuComponent from './HeaderMenuComponent'

const { Content } = Layout

function LayoutComponent({ children }) {

    const [collapsed, setCollapsed] = useState(false)

    const toggle = () => {
        setCollapsed(!collapsed)
    }

    useEffect(() => {
        window.screen.width < 576 && setCollapsed(true)
    }, [])

    return (
        <Layout>
            <SidebarComponent collapsed={collapsed} />
            <Layout className="site-layout">
                <HeaderMenuComponent toggle={toggle} collapsed={collapsed} />
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        overflow: "auto"
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutComponent