import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useInitPaymenthsMethods from '../hooks/useInitPaymenthsMethods'
import useInitPlansData from '../hooks/useInitPlasnsData'
import PaymenthsMethodsTable from './PaymentsMethodsTable'
import PlansTable from './PlansTable'

function PaymentsMethods() {

    const params = useParams()

    const medios = useRef(null)
    const planes = useRef(null)

    const scrollIntoViewHandler = (el) => {
        el.scrollIntoView({ behavior: "smooth" })
    }

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const paymenthsMethods = useSelector(state => state.paymenths.paymenthsMethods)
    const plansData = useSelector(state => state.paymenths.plansData)

    useInitPaymenthsMethods({ data: paymenthsMethods, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate })
    useInitPlansData({ data: plansData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, plan: true })

    useEffect(() => {
        params.section === "medios" && scrollIntoViewHandler(medios?.current)
        params.section === "planes" && scrollIntoViewHandler(planes?.current)
    }, [params.section])

    return (
        <>
            <div>
                <h1 ref={medios}>Medios</h1>
                <PaymenthsMethodsTable />
            </div>
            <div>
                <h1 ref={planes}>Planes</h1>
                <PlansTable />
            </div>
        </>
    )
}

export default PaymentsMethods