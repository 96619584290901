export const config = {
    xField: 'label',
    yField: ['Cantidad', 'Facturación'],
    yAxis: {
        Facturación: {
            min: 0,
            label: {
                position: 'middle',
                formatter: (val) => {
                    return new Intl.NumberFormat('es-AR', { currency: "ARS", style: "currency" }).format(val)
                }
            }
        },
        Cantidad: true,
    },
    geometryOptions: [
        {
            geometry: 'line',
            smooth: true,
            color: '#5B8FF9',
            label: {
                position: 'top',
                formatter: function formatter(item) {
                    return ''.concat(item.Cantidad)
                },
                style: {
                    fill: 'black', //color del texto
                    fontWeight: 'bold'
                }
            }
        },
        {
            geometry: 'column',
            color: '#5849cc',
            columnWidthRatio: 0.7,
            label: {
                position: 'middle',
                formatter: function formatter(item) {
                    // return ''.concat(datum.Facturación, '个')
                    return new Intl.NumberFormat('es-AR', { currency: "ARS", style: "currency" }).format(item.Facturación)
                },
            },
        },

    ],
    interactions: [{ type: 'element-highlight' }, { type: 'active-region' }],
    tooltip: {
        customContent: (title, items) => {
            return (
                <>
                    <h5 style={{ marginTop: 16 }}>{title}</h5>
                    <ul style={{ paddingLeft: 0 }}>
                        {items?.map((item, index) => {
                            const { name, value, color } = item
                            return (
                                <li
                                    key={item.value}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
                                >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span
                                        style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                                    >
                                        <span style={{ margiRight: 16 }}>{name}: </span>
                                        <span className="g2-tooltip-list-item-value">{name === 'Facturación' ? new Intl.NumberFormat('es-AR', { currency: "ARS", style: "currency" }).format(value) : value}</span>
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </>
            )
        },
    },
}