import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row, Select, Table } from 'antd'
import { useSelector } from 'react-redux'
import { monthsLargeDictionary, monthsLargeNumberDictionary } from '../../utils/monthsDictionary'
import { useGetFilters } from '../hooks/useGetFilters'

function QuantityByBrandOffices() {

    const motorBikesData = useSelector(state => state.motorBikes.motorBikesData)

    const [yearToFilter, setYearToFilter] = useState(0)
    const [zonesToFilter, setZonesToFilter] = useState([])
    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    })

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const { fDate, eDate } = datesToFilter

    const { years, zones } = useGetFilters(motorBikesData)

    useEffect(() => {
        setYearToFilter(years[0])
    }, [years, zones])

    const tableDictionary = {
        brandOffice: 'Sucursal',
        total: 'Total'
    }

    const normalizeMotorBikesData = useCallback((data = [], year, zone = []) => {
        const reduceFilteredData = [...data]
            .filter(dato => zone.length > 0 ? (zone.includes(dato.ZONA) && year === dato.ANIO) : year === dato.ANIO)
            .reduce((accum, val) => {
                const { MES, Cantidad, BPLId, Address } = val
                return {
                    ...accum,
                    [BPLId]: {
                        brandOffice: Address,
                        ...accum?.[BPLId],
                        [monthsLargeDictionary[MES]]: (accum?.[BPLId]?.[monthsLargeDictionary[MES]] || 0) + Cantidad,
                        total: (accum?.[BPLId]?.total || 0) + Cantidad
                    }
                }
            }, {})

        if (!!reduceFilteredData) {
            if (Object.keys(reduceFilteredData).length > 0) {
                const columns = Object.keys(reduceFilteredData[Object.keys(reduceFilteredData)[0]]).map(col => {
                    if (!col.includes("number-")) return {
                        title: tableDictionary[col] ?? col,
                        dataIndex: col,
                        key: col,
                        align: tableDictionary[col] === 'Sucursal' ? 'left' : 'center',
                        ellipsis: {
                            showTitle: true,
                        },
                        width: tableDictionary[col] === 'Sucursal' ? "25%" : '15%',
                        order: col === 'brandOffice' ? -1 : col === 'total' ? 14 : monthsLargeNumberDictionary[col],
                        sorter: col === 'brandOffice' ? (a, b) => a[col].localeCompare(b[col]) : (a, b) => b[col] - a[col],

                    }
                }).sort((a, b) => a.order - b.order).filter(col => col)

                const rows = Object.keys(reduceFilteredData).map(key => reduceFilteredData[key])

                setTableData({ ...tableData, columns: columns, rows: rows })
            }
        }
    }, [])

    useEffect(() => {
        if (motorBikesData.length > 0) {
            normalizeMotorBikesData(motorBikesData, yearToFilter ?? years[0], zonesToFilter)
        }
    }, [yearToFilter, zonesToFilter, motorBikesData])

    return (
        <>
            <Table
                scroll={{ y: 320, x: true }}
                size="small"
                loading={tableData?.rows?.length === 0 && !!fDate && !!eDate}
                title={() =>
                    <div>
                        <h3 style={{ textAlign: 'center' }}>
                            Unidades por Sucursal
                        </h3>
                        <Row justify="end" align="middle">
                            <Col xs={24} md={4} >
                                {
                                    years?.length > 1 &&
                                    <Select
                                        size="small"
                                        placeholder="Elegir Año"
                                        onChange={e => setYearToFilter(e)}
                                        defaultValue={years[0]}
                                        style={{ width: '80%' }}
                                    >
                                        {
                                            years?.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)
                                        }
                                    </Select>
                                }
                            </Col>
                            <Col xs={24} md={8} >
                                {
                                    zones?.length > 0 &&
                                    <Select
                                        mode="multiple"
                                        size="small"
                                        placeholder="Elegir Zonas"
                                        // defaultValue={["Todas"]}
                                        onChange={e => setZonesToFilter(e)}
                                        style={{ width: '100%' }}
                                    >
                                        {zones?.filter(dato => dato).map(val => <Select.Option key={val} value={val}>{val}</Select.Option>)}
                                    </Select>
                                }

                            </Col>
                        </Row>

                    </div>
                }
                rowKey="brandOffice"
                pagination={{ position: ["bottomCenter"] }}
                columns={tableData.columns}
                dataSource={tableData.rows}
            />
        </>
    )
}

export default QuantityByBrandOffices