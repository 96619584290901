import { clientAxios } from '../../../config'

export const getSellersBikesData = async ({ fDate, eDate, division }) => {
    try {
        const resultGetSellersBikesData = await clientAxios.get('/sellers.php', {
            params: {
                fDate,
                eDate,
                division
            },
        })
        const { result, message = "", error_msg } = resultGetSellersBikesData.data
        return { result, message, error_msg }

    } catch (error) {
        if ("response" in error) {
            const { response } = error
            const { data } = response
            const { result = [], message = "", error_msg = "" } = data
            return { result, message, error_msg }
        } else {
            const result = [], message = "An error has ocurred", error_msg = ""
            return { result, message, error_msg }
        }
    }
}