import React, { useEffect } from 'react'
import { Form, Input, Button } from 'antd'
import { formatDateOnKeyPress, formatDateOncChangeHandler, formatDateOnPasteHandler } from '../utils/normalizeDate'
import { setDatesToFilter } from '../redux/filterDatesAndUserReducer'
import { useDispatch } from 'react-redux'

function FormFilterDates({ screenWidth, modalForm }) {

    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const currentDate = new Date()
    const date = new Date()
    date.setDate(1)
    date.setMonth(0)
    const dateCurrentMonth = `${currentDate.getDate().toString().padStart(2, "0")}/${currentDate.getMonth((currentDate.setMonth(currentDate.getMonth() + 1))).toString().padStart(2, "0")}/${currentDate.getFullYear()}`
    const firstDateCurrentMonth = `${date.getDate().toString().padStart(2, "0")}/${date.getMonth((date.setMonth(date.getMonth() + 1))).toString().padStart(2, "0")}/${date.getFullYear()}`

    const handlerChange = e => {
        const formatedDate = formatDateOncChangeHandler(e)
        if (!!formatedDate) {
            form.setFieldsValue({ [e.target.name]: formatedDate })
            !!modalForm && modalForm.setFieldsValue({ [e.target.name]: formatedDate })
        } else {
            form.setFieldsValue({ [e.target.name]: '' })
            !!modalForm && modalForm.setFieldsValue({ [e.target.name]: '' })
        }
    }

    const handlerOnPaste = e => {
        const formatedDate = formatDateOnPasteHandler(e)
        e.preventDefault()
        if (!!formatedDate) {
            form.setFieldsValue({ [e.target.name]: formatedDate })
            !!modalForm && modalForm.setFieldsValue({ [e.target.name]: formatedDate })
        }
    }

    const handlerOnKeyPress = e => {
        formatDateOnKeyPress(e)
    }

    useEffect(() => {
        form.setFieldsValue({ fDate: firstDateCurrentMonth, eDate: dateCurrentMonth })
        !!modalForm && modalForm.setFieldsValue({ fDate: firstDateCurrentMonth, eDate: dateCurrentMonth })
        dispatch(setDatesToFilter({ fDate: firstDateCurrentMonth, eDate: dateCurrentMonth }))
    }, [])

    return (
        <Form
            form={!!modalForm ? modalForm : form}
            size="small"
            layout={screenWidth > 576 ? "inline" : "vertical"}
            onFinish={({ fDate, eDate }) => {
                dispatch(setDatesToFilter({ fDate, eDate }))
            }}
        >
            <Form.Item
                label="F. Inicio"
                name="fDate"
            >
                <Input
                    name="fDate"
                    type="text"
                    maxLength={10}
                    onChange={handlerChange}
                    onKeyDown={handlerOnKeyPress}
                    onPaste={handlerOnPaste}
                    onClick={e => e.target.select()}
                    placeholder="Ej. 01/01/2021"
                />
            </Form.Item>
            <Form.Item
                label="F. Fin"
                name="eDate"
            >
                <Input
                    name="eDate"
                    type="text"
                    maxLength={10}
                    onChange={handlerChange}
                    onKeyDown={handlerOnKeyPress}
                    onPaste={handlerOnPaste}
                    onClick={e => e.target.select()}
                    placeholder="Ej. 01/01/2021"
                />
            </Form.Item>
            {
                screenWidth > 576
                    ?
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Buscar
                        </Button>
                    </Form.Item>
                    :
                    null
            }
        </Form>
    )
}

export default FormFilterDates