import { clientAxios } from "../../../config"

export const getDivisionSparesData = async ({ fDate, eDate, division, date }) => {
    try {
        const resultGetDivisionSparesData = await clientAxios.get('/division-spares.php', {
            params: {
                fDate,
                eDate,
                division,
                date
            },
            withCredentials: true
        })
        const { result = [], message = "", msg_error = "" } = resultGetDivisionSparesData.data
        return { result, message, msg_error }

    } catch (error) {
        if ("response" in error) {
            const { response } = error
            const { data } = response
            const { result = [], message = "", error_msg = "" } = data
            return { result, message, error_msg }
        } else {
            const result = [], message = "An error has ocurred", error_msg = ""
            return { result, message, error_msg }
        }
    }
}