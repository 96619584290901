import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Select, Table } from 'antd'
import { monthsLargeDictionary, monthsLargeNumberDictionary } from '../../utils/monthsDictionary'
import { useGetFilters } from '../hooks/useGetFilters'

function SellersByQuantityTable() {

    const bikesSellersData = useSelector(state => state.bikes.bikesSellersData)
    const [yearToFilter, setYearToFilter] = useState(0)
    const [zonesToFilter, setZonesToFilter] = useState([])
    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    })

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const { fDate, eDate } = datesToFilter

    const tableDictionary = {
        sellerName: 'Vendedor',
        total: 'Total'
    }

    const { years, zones } = useGetFilters(bikesSellersData)

    useEffect(() => {
        setYearToFilter(years[0])
    }, [years, zones])

    const normalizeData = useCallback((data = [], year, zones = []) => {
        const reduceFilteredData = [...data]
            .filter(dato => zones.length > 0 ? (zones.includes(dato.ZONA) && year === dato.ANIO) : year === dato.ANIO)
            .reduce((accum, val) => {
                const { MES, Cantidad, BPLId, SlpName } = val
                return {
                    ...accum,
                    [BPLId]: {
                        sellerName: SlpName,
                        ...accum?.[BPLId],
                        [monthsLargeDictionary[MES]]: (accum?.[BPLId]?.[monthsLargeDictionary[MES]] || 0) + Cantidad,
                        total: (accum?.[BPLId]?.total || 0) + Cantidad
                    }
                }
            }, {})

        if (!!reduceFilteredData) {
            if (Object.keys(reduceFilteredData).length > 0) {
                const columns = Object.keys(reduceFilteredData[Object.keys(reduceFilteredData)[0]]).map(col => {
                    if (!col.includes("number-")) return {
                        title: tableDictionary[col] ?? col,
                        dataIndex: col,
                        key: col,
                        align: tableDictionary[col] === 'Vendedor' ? 'left' : 'center',
                        ellipsis: {
                            showTitle: true,
                        },
                        width: tableDictionary[col] === 'Vendedor' ? "25%" : 'default',
                        order: col === 'sellerName' ? -1 : col === 'total' ? 14 : monthsLargeNumberDictionary[col],
                        sorter: col === 'sellerName' ? (a, b) => a[col].localeCompare(b[col]) : (a, b) => b[col] - a[col]
                    }
                }).sort((a, b) => a.order - b.order).filter(col => col)

                const rows = Object.keys(reduceFilteredData).map(key => reduceFilteredData[key])

                setTableData({ ...tableData, columns: columns, rows: rows })
            }
        }
    }, [yearToFilter])

    useEffect(() => {
        if (bikesSellersData.length > 0) {
            normalizeData(bikesSellersData, yearToFilter ?? years[0], zonesToFilter)
        }

    }, [yearToFilter, zonesToFilter, bikesSellersData])

    return (
        <>
            <Table
                scroll={{ y: 320 }}
                size="small"
                loading={tableData?.rows.length === 0 && !!fDate && !!eDate}
                title={() =>
                    <div>
                        <h3 style={{ textAlign: 'center' }}>
                            Unidades por Vendedor
                        </h3>
                        <Row justify="end" align="middle">
                            <Col xs={24} md={4} >
                                {
                                    years?.length > 1 &&
                                    <Select
                                        size="small"
                                        placeholder="Elegir Año"
                                        onChange={e => setYearToFilter(e)}
                                        defaultValue={years[0]}
                                        style={{ width: '80%' }}
                                    >
                                        {
                                            years?.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)
                                        }
                                    </Select>
                                }
                            </Col>
                            <Col xs={24} md={8} >
                                {
                                    zones?.length > 0 &&
                                    <Select
                                        mode="multiple"
                                        size="small"
                                        placeholder="Elegir Zonas"
                                        onChange={e => setZonesToFilter(e)}
                                        style={{ width: '100%' }}
                                    >
                                        {zones?.filter(dato => dato).map(val => <Select.Option key={val} value={val}>{val}</Select.Option>)}
                                    </Select>
                                }

                            </Col>
                        </Row>
                    </div>
                }
                pagination={{ position: ["bottomCenter"] }}
                rowKey={(e) => Object.keys(e).map(key => e[key])}
                columns={tableData.columns}
                dataSource={tableData.rows}
            />
        </>
    )
}

export default SellersByQuantityTable