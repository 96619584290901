import { Col, Row, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { monthsLargeDictionary, monthsLargeNumberDictionary } from '../../utils/monthsDictionary'
import { useGetFilters } from '../hooks/useGetFilters'

function BrandOfficesByBillingTable() {

    const bikesData = useSelector(state => state.bikes.bikesData)
    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const { fDate, eDate } = datesToFilter

    const [zonesToFilter, setZonesToFilter] = useState([])
    const [yearToFilter, setYearToFilter] = useState(0)
    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    })

    const tableDictionary = {
        brandOffice: 'Sucursal',
        total: 'Total'
    }

    const { years, zones } = useGetFilters(bikesData)

    useEffect(() => {
        setYearToFilter(years[0])
    }, [years, zones])

    const normalizeMotorHomeData = (data = [], year, zones = []) => {
        const reduceFilteredData = [...data]
            .filter(dato => zones.length > 0 ? (zones.includes(dato.ZONA) && year === dato.ANIO) : year === dato.ANIO)
            .reduce((accum, val) => {
                const { MES, MontoVenta, BPLName, Address } = val

                const mesInDictionary = monthsLargeDictionary[MES]

                return {
                    ...accum,
                    [BPLName]: {
                        brandOffice: Address,
                        ...accum?.[BPLName],
                        ["number-" + mesInDictionary]: (accum?.[BPLName]?.["number-" + mesInDictionary] || 0) + MontoVenta,
                        "number-total": (accum?.[BPLName]?.["number-total"] || 0) + MontoVenta,
                        total: new Intl.NumberFormat('es-AR', { currency: 'ARS', style: 'currency' }).format((accum?.[BPLName]?.["number-total"] || 0) + MontoVenta),
                        [monthsLargeDictionary[MES]]: new Intl.NumberFormat('es-AR', { currency: 'ARS', style: 'currency' }).format((accum?.[BPLName]?.["number-" + mesInDictionary] || 0) + MontoVenta)
                    }
                }
            }, {})

        if (!!reduceFilteredData) {
            if (Object.keys(reduceFilteredData).length > 0) {
                setTableData({
                    ...tableData, columns: Object.keys(reduceFilteredData[Object.keys(reduceFilteredData)[0]]).map(col => {
                        if (!col.includes("number-") || !col.includes("number-")) return {
                            title: tableDictionary[col] ?? col,
                            dataIndex: col,
                            key: col,
                            align: tableDictionary[col] === 'Sucursal' ? 'left' : 'center',
                            ellipsis: {
                                showTitle: true,
                            },
                            width: tableDictionary[col] === 'Sucursal' ? "25%" : 'default',
                            order: col === 'brandOffice' ? -1 : col === 'total' ? 14 : monthsLargeNumberDictionary[col],
                            sorter: col === 'brandOffice' ? (a, b) => a[col].localeCompare(b[col]) : (a, b) => b[col]?.replace('$ ', '')?.replaceAll('.', '')?.replace(',', '.') - a[col]?.replace('$ ', '')?.replaceAll('.', '')?.replace(',', '.'),
                        }
                    }).sort((a, b) => a.order - b.order).filter(col => col), rows: Object.keys(reduceFilteredData).map(key => reduceFilteredData[key])
                })
            }
        }

    }

    useEffect(() => {
        if (bikesData.length > 0) {
            normalizeMotorHomeData(bikesData, yearToFilter ?? years[0], zonesToFilter)
        }
    }, [yearToFilter, zonesToFilter, bikesData])

    return (
        <>
            <Table
                scroll={{ y: 320, x: true }}
                size="small"
                loading={tableData?.rows?.length === 0 && !!fDate && !!eDate}
                title={() =>
                    <div>
                        <h3 style={{ textAlign: 'center' }}>
                            Facturación Mensual por Sucursal
                        </h3>
                        <Row justify="end" align="middle">
                            <Col xs={24} md={4} >
                                {
                                    years?.length > 1 &&
                                    <Select
                                        size="small"
                                        placeholder="Elegir Año"
                                        onChange={e => setYearToFilter(e)}
                                        defaultValue={years[0]}
                                        style={{ width: '80%' }}
                                    >
                                        {
                                            years?.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)
                                        }
                                    </Select>
                                }
                            </Col>
                            <Col xs={24} md={8} >
                                {
                                    zones?.length > 0 &&
                                    <Select
                                        mode="multiple"
                                        size="small"
                                        placeholder="Elegir Zonas"
                                        // defaultValue={["Todas"]}
                                        onChange={e => setZonesToFilter(e)}
                                        style={{ width: '100%' }}
                                    >
                                        {zones?.filter(dato => dato).map(val => <Select.Option key={val} value={val}>{val}</Select.Option>)}
                                    </Select>
                                }

                            </Col>
                        </Row>
                    </div>
                }
                pagination={{ position: ["bottomCenter"] }}
                rowKey={(e) => Object.keys(e).map(key => e[key])}
                columns={tableData.columns}
                dataSource={tableData.rows}
            />
        </>
    )
}

export default BrandOfficesByBillingTable