import React from 'react'
import { Card } from 'antd'

const { Meta } = Card

function CardsElement({ title, billing, quantity }) {

    const pageWidth = window.screen.width

    return (
        <>
            <Card
                hoverable
                style={{ width: pageWidth > 576 ? 240 : "100%", margin: "5px" }}
            >
                <Meta
                    title={title}
                    description={`Facturación: ${!!billing ? new Intl.NumberFormat("es-AR", { currency: "ARS", style: "currency" }).format(billing) : "$ 0"} Cantidad: ${!!quantity ? quantity : 0}`}
                />
            </Card>
        </>
    )
}

export default CardsElement