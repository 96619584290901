import React from 'react'
import { auth } from './auth'
import { Route, Redirect } from 'react-router-dom'

export default function PrivateRoute({ component: Component, role, ...rest }) {

    return (
        <Route {...rest} render={(props) => (
            auth.isAuthenticated()
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />
        )} />
    )
}
