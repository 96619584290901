export const actionsForMotorBikes = {
    SET_MOTORBIKESDATA: "set-motorbikesData",
    SET_SELLERSMOTORBIKES: "set-sellersMotorBikes",
    SET_ZONESMOTORBIKESDATA: "set-zoneMotorBikesData",
    SET_PRODUCTSMOTORBIKESDATA: "set-productsMotorBikesData",
    SET_PRODUCTSMOTORBIKESDATABYDATE: "set-productsMotorBikesDataByDate",
    SET_YEARSINDATA: "set-yearsInData",
    SET_COMPAREDATA: "set-compareData"
}

export const actionsForBikes = {
    SET_BIKESDATA: "set-bikesData",
    SET_SELLERSDIVISIONBIKES: "set-sellersDivisionBikes",
    SET_PRODUCTSDIVISIONBIKES: "set-productsDivisionBikes",
    SET_PRODUCTSDIVISIONBIKESBYDATE: "set-productsDivisionBikesByDate",
    SET_COMPAREBIKESDATA: "set-comparaBikesData"
}

export const actionsForUser = {
    SET_USERDATA: "set-userData",
    SET_FILTERDATES: "set-filterDates"
}

export const actionsForPaymenthsMethods = {
    SET_PAYMENTHSMETHODS: "set-paymenthsMethods",
    SET_PLANSDATA: "set-plansData",
}

export const actionsForDivisionHome = {
    SET_DIVISIONHOMEDATA: "set-divisionHomeData",
    SET_SELLERSDIVISIONHOME: "set-sellersDivisionHome",
    SET_PRODUCTSDIVISIONHOME: "set-productsDivisionHome",
    SET_PRODUCTSDIVISIONHOMEBYDATE: "set-productsDivisionHomeByDate",
    SET_COMPAREHOMEDATA: "set-compareHomeData"
}

export const actionsForDivisionSpares = {
    SET_DIVISIONSPARESDATA: "set-divisionSparesData",
    SET_SELLERSDIVISIONSPARES: "set-sellersDivisionSpares",
    SET_PRODUCTSDIVISIONSPARES: "set-productsDivisionSpares",
    SET_PRODUCTSDIVISIONSPARESBYDATE: "set-productsDivisionSparesByDate",
    SET_COMPARESPARESDATA: "set-compareSparesData"
}