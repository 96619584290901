import React, { useEffect, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import FormFilterDates from './FormFilterDates'
import ModalFilterDates from './ModalFilterDates'
const { Header } = Layout

function HeaderMenuComponent({ toggle, collapsed }) {

    const [screenWidth, setScreenWidth] = useState()

    useEffect(() => {
        const width = window.screen.width
        setScreenWidth(width)
    }, [])

    useEffect(() => {
        const width = window.screen.width
        setScreenWidth(width)
    }, [window.screen.width])

    return (
        <Header className="site-layout-background" style={{ padding: 0 }}>
            <Row align="middle" justify="space-around">
                <Col xs={12} md={12} >
                    {
                        collapsed ?
                            <MenuUnfoldOutlined
                                className='trigger'
                                onClick={toggle}
                            />
                            :
                            <MenuFoldOutlined
                                className='trigger'
                                onClick={toggle}
                            />
                    }
                </Col>
                <Col span={12}>
                    {
                        screenWidth > 576 ?
                            <FormFilterDates screenWidth={screenWidth} />
                            :
                            <ModalFilterDates />
                    }
                </Col>
            </Row>
        </Header>
    )
}

export default HeaderMenuComponent