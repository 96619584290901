import React from 'react'
import { Col, Row, Empty, Divider } from 'antd'
import { useSelector } from 'react-redux'
import BarsGraphycBrandOfficesBilling from '../components/divisionMotorBikes/BarsGraphycBrandOfficesBilling'
import { useInitMotorBikesData } from '../components/hooks/useInitMotorBikesData'
import BarsGraphycDivisionHome from '../components/divisionHome/BarsGraphycDivisionHome'
import { useInitDivisionHomeData } from '../components/hooks/useInitDivisionHomeData'
import { useInitDivisionBikesData } from '../components/hooks/useInitDivisionBikesData'
import BarsGraphycDivisionBikes from '../components/divisionBikes/BarsGraphycDivisionBikes'
import BarsGraphycDivisionSpares from '../components/divisionSpares/BarsGraphycDivisionSpares'
import { useInitDivisionSparesData } from '../components/hooks/useInitDivisionSparesData'
import ComparativeCards from '../components/ComparativeCards'

function HomePage() {

    const motorBikesData = useSelector(state => state.motorBikes.motorBikesData)
    const compareMotorBikes = useSelector(state => state.motorBikes.compareMotorBikes)
    const homeData = useSelector(state => state.home.homeData)
    const compareHome = useSelector(state => state.home.compareHome)
    const bikesData = useSelector(state => state.bikes.bikesData)
    const compareBikes = useSelector(state => state.bikes.compareBikes)
    const sparesData = useSelector(state => state.spares.sparesData)
    const compareSpares = useSelector(state => state.spares.compareSpares)
    const datesToFilter = useSelector(state => state.user.datesToFilter)

    useInitMotorBikesData({ data: motorBikesData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate })
    useInitDivisionHomeData({ data: homeData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate })
    useInitDivisionBikesData({ data: bikesData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate })
    useInitDivisionSparesData({ data: sparesData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate })

    const titleStyle = {
        textAlign: "center",
        fontSize: "1.8rem",
        marginTop: "10px",
    }

    return (
        <Row justify="center" align="middle">
            <Col span={24}>
                <h2 style={titleStyle}>División Motos</h2>
                {
                    motorBikesData?.length > 0 ?
                        <BarsGraphycBrandOfficesBilling />
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                <ComparativeCards division="motorBikes" arrayData={compareMotorBikes} />
            </Col>
            <Divider />
            <Col span={24}>
                <h2 style={titleStyle}>División Hogar</h2>
                {
                    homeData?.length > 0 ?
                        <BarsGraphycDivisionHome />
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                <ComparativeCards division="home" arrayData={compareHome} />
            </Col>
            <Divider />
            <Col span={24}>
                <h2 style={titleStyle}>División Bikes</h2>
                {
                    bikesData?.length > 0 ?
                        <BarsGraphycDivisionBikes />
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                <ComparativeCards division="bikes" arrayData={compareBikes} />
            </Col>
            <Divider />
            <Col span={24}>
                <h2 style={titleStyle}>División Repuestos</h2>
                {
                    sparesData?.length > 0 ?
                        <BarsGraphycDivisionSpares />
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                <ComparativeCards division="spares" arrayData={compareSpares} />
            </Col>
        </Row>
    )
}

export default HomePage