import { clientAxios } from "../../../config"

async function loginHandler({ UserName, Password }) {
    try {
        const resultLogin = await clientAxios.post('/login.php', {
            UserName,
            Password,
            login: true
        })
        const { result = [], message = "", msg_error = "" } = resultLogin.data
        return { result, message, msg_error }
    } catch (error) {
        if ("response" in error) {
            const { response } = error
            const { data } = response
            const { result = [], message = "", error_msg = "" } = data
            return { result, message, error_msg }
        } else {
            const result = [], message = "An error has ocurred", error_msg = ""
            return { result, message, error_msg }
        }
    }
}

export default loginHandler