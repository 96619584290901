import { Row } from 'antd'
import React from 'react'

function SectionUnderBuild(props) {
    return (
        <Row justify="center" align="middle" style={{ minHeight: '70vh' }}>
            <h1>Sección en Construcción</h1>
        </Row>
    )
}

export default SectionUnderBuild