import { useEffect, useState } from 'react'

export const useGetFilters = (data = []) => {

    const [years, setYears] = useState([])
    const [zones, setZones] = useState([])
    const [months, setMonths] = useState([])

    useEffect(() => {


        const yearsInData = data.reduce((accum, val) => {
            if (accum.includes(val.ANIO)) {
                return accum
            } else {
                return [...accum, val.ANIO]
            }

        }, [])
        setYears(yearsInData)

        const zonesInData = data.reduce((accum, val) => {

            if (accum.includes(val.ZONA)) {
                return accum
            } else {
                return [...accum, val.ZONA]
            }

        }, [])
        setZones(zonesInData)

        const monthsInData = data.reduce((accum, val) => {

            if (accum.includes(val.MES)) {
                return accum
            } else {
                return [...accum, val.MES]
            }

        }, [])
        setMonths(monthsInData)
    }, [data])

    return { years, zones, months }


}