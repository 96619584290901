import React from 'react'
import { hydrate, render } from "react-dom"
import 'antd/dist/antd.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './redux/store'

const rootElement = document.getElementById("root")
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <App />
  </Provider>, rootElement)
} else {
  render(<Provider store={store}>
    <App />
  </Provider>, rootElement)
}

reportWebVitals()
