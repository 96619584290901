import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useInitMotorBikesData } from '../hooks/useInitMotorBikesData'
import { useInitProductsByDate } from '../hooks/useInitProductByDate'
import { useInitProductsData } from '../hooks/useInitProductsData'
import { useInitSellersData } from '../hooks/useInitSellerData'
import BillingByBrandOffices from './BillingByBrandOfficesTable'
import QuantityByBrandOffices from './QuantityByBrandOfficesTable'
import SellersBillingTable from './SellersBillingTable'
import SellersQuantityTable from './SellersQuantityTable'
import VehiclesGraphyc from './VehiclesGraphyc'
import VehiclesQuantitiesTable from './VehiclesQuantitiesTable'


function MotorBikes() {

    const params = useParams()

    const sucursales = useRef(null)
    const vehiculos = useRef(null)
    const vendedores = useRef(null)
    const division = "motos"

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const motorBikesProductsDataByDate = useSelector(state => state.motorBikes.motorBikesProductsDataByDate)
    const motorBikesProductsData = useSelector(state => state.motorBikes.motorBikesProductsData)
    const motorBikesSellerData = useSelector(state => state.motorBikes.motorBikesSellerData)
    const motorBikesData = useSelector(state => state.motorBikes.motorBikesData)

    useInitProductsByDate({ data: motorBikesProductsDataByDate, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division, date: true })
    useInitProductsData({ data: motorBikesProductsData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division })
    useInitSellersData({ data: motorBikesSellerData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division })
    useInitMotorBikesData({ data: motorBikesData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division })

    useEffect(() => {
        if (params.section === 'sucursales') {
            sucursales?.current?.scrollIntoView({ behavior: "smooth" })
        }
        if (params.section === 'vehiculos') {
            vehiculos?.current?.scrollIntoView({ behavior: "smooth" })
        }
        if (params.section === 'vendedores') {
            vendedores?.current?.scrollIntoView({ behavior: "smooth" })
        }


    }, [params.section])

    return (
        <>
            <div>
                <h2 ref={sucursales} >Sucursales</h2>
                <BillingByBrandOffices />
                <QuantityByBrandOffices />
            </div>
            <div>
                <h2 ref={vendedores}>Vendedores</h2>
                <SellersBillingTable />
                <SellersQuantityTable />
            </div>
            <div>
                <h2 ref={vehiculos}>Vehículos</h2>
                <VehiclesQuantitiesTable />
                <VehiclesGraphyc />
            </div>
        </>
    )
}

export default MotorBikes