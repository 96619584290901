import React from 'react'
import { Row } from 'antd'
import LoginComponent from '../components/login'

function LoginPage() {

    return (
        <Row
            justify="center"
            align="middle"
            style={{
                height: "100vh",
                backgroundColor: "whitesmoke"
            }}
        >
            <LoginComponent />
        </Row>
    )
}

export default LoginPage