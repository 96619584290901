import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setProductsBikesDataByDate } from '../../redux/divisionBikesReducer'
import { setProductsHomeByDateData } from '../../redux/divisionHomeReducer'
import { setProducsSparesByDateData } from '../../redux/divisionSparesReducer'
import { setProductsMotorBikesDataByDate } from '../../redux/divisionMotorBikesReducer'
import getProductsBikesData from '../divisionBikes/services/getProductsDivisionBikes'
import { getProductHomeData } from '../divisionHome/servicios/getProductsHomeData'
import { getProductSparesData } from '../divisionSpares/services/getProductsDivisionSparesData'
import { errorMessage, openMessage, successfullyMessage } from '../Messages'
import { getProductsMotorBikesData } from '../divisionMotorBikes/services/getPorductsMotorBikes'

export function useInitProductsByDate({ data, fDate, eDate, division, date }) {

    const dispatch = useDispatch()
    const [lastFilterDates, setLastFilterDates] = useState({})

    const initMotorBikeData = useCallback(async () => {
        openMessage({ message: "Cargando..." })
        if (division === "motos") {
            const { result, message, error_msg } = await getProductsMotorBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                // return { result }
                dispatch(setProductsMotorBikesDataByDate(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "hogar") {
            const { result, message, error_msg } = await getProductHomeData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                // return { result }
                dispatch(setProductsHomeByDateData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "bikes") {
            const { result, message, error_msg } = await getProductsBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                // return { result }
                dispatch(setProductsBikesDataByDate(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "repuestos") {
            const { result, message, error_msg } = await getProductSparesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                // return { result }
                dispatch(setProducsSparesByDateData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
    }, [dispatch, fDate, eDate, division, date])

    useEffect(() => {
        (async () => {
            if (data?.length > 0 && !!fDate && !!eDate) {
                if (fDate != lastFilterDates?.fDate || eDate != lastFilterDates?.eDate) {
                    initMotorBikeData()
                    setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
                }
            } else if (data?.length === 0 && !!fDate && !!eDate) {
                initMotorBikeData()
                setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
            }
        })()
    }, [initMotorBikeData, data, fDate, eDate, division, date])

}