import { actionsForBikes } from './actions'
import { initialStateDivisionBikes } from './initialState'

export const divisionBikesReducer = (state = initialStateDivisionBikes, action) => {
    const { type, payload } = action
    switch (type) {
        case actionsForBikes.SET_BIKESDATA:
            return {
                ...state,
                bikesData: payload
            }
        case actionsForBikes.SET_SELLERSDIVISIONBIKES:
            return {
                ...state,
                bikesSellersData: payload
            }
        case actionsForBikes.SET_PRODUCTSDIVISIONBIKES:
            return {
                ...state,
                bikesProductsData: payload
            }
        case actionsForBikes.SET_PRODUCTSDIVISIONBIKESBYDATE:
            return {
                ...state,
                bikesProductsDataByDate: payload
            }
        case actionsForBikes.SET_COMPAREBIKESDATA:
            return {
                ...state,
                compareBikes: payload
            }
        default:
            return state
    }
}

export const setDivisionBikesData = content => ({
    type: actionsForBikes.SET_BIKESDATA,
    payload: content
})

export const setSellersBikesData = content => ({
    type: actionsForBikes.SET_SELLERSDIVISIONBIKES,
    payload: content
})

export const setProductsBikesData = content => ({
    type: actionsForBikes.SET_PRODUCTSDIVISIONBIKES,
    payload: content
})

export const setProductsBikesDataByDate = content => ({
    type: actionsForBikes.SET_PRODUCTSDIVISIONBIKESBYDATE,
    payload: content
})

export const setCompareBikesData = content => ({
    type: actionsForBikes.SET_COMPAREBIKESDATA,
    payload: content
})