import { Row } from "antd"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import CardsElement from "../elements/CardsElement"
import { setCompareBikesData } from "../redux/divisionBikesReducer"
import { setCompareHomeData } from "../redux/divisionHomeReducer"
import { setCompareDataMotorBikes } from "../redux/divisionMotorBikesReducer"
import { setCompareSparesData } from "../redux/divisionSparesReducer"
import { filterDates } from '../utils/datesForCompareCards'
import { getDivisionBikesData } from "./divisionBikes/services/getDivisionBikesData"
import { getDivisionHomeData } from "./divisionHome/servicios/getDivisionHomeData"
import { getMotorBikesData } from "./divisionMotorBikes/services/getMotorBikesData"
import { getDivisionSparesData } from "./divisionSpares/services/getDivisionSparesData"

function ComparativeCards({ division, arrayData }) {

    const dispatch = useDispatch()

    const pageWidth = window.screen.width

    const dictionary = {
        today: "Hoy",
        yesterday: "Ayer",
        towDatesBefore: "2 días Atrás",
        currentMonth: "Este Mes",
        lastMonth: "Mes Pasado",
        twoMonthsBefore: "2 Meses Atrás"
    }

    const getData = async () => {
        const datos = await Promise.all(Object.entries(filterDates).map(async ([accesKey, dates]) => {
            const { fDate, eDate } = dates
            if (division === "motorBikes") {
                const { result } = await getMotorBikesData({ fDate, eDate })
                return {
                    [dictionary[accesKey]]: result.reduce((accum, val) => {
                        const { Cantidad, MontoVenta } = val
                        return {
                            ...accum,
                            Cantidad: (accum["Cantidad"] || 0) + Cantidad,
                            Facturación: (accum["Facturación"] || 0) + MontoVenta
                        }
                    }, {})
                }
            }
            if (division === "bikes") {
                const { result } = await getDivisionBikesData({ fDate, eDate })
                return {
                    [dictionary[accesKey]]: result.reduce((accum, val) => {
                        const { Cantidad, MontoVenta } = val
                        return {
                            ...accum,
                            Cantidad: (accum["Cantidad"] || 0) + Cantidad,
                            Facturación: (accum["Facturación"] || 0) + MontoVenta
                        }
                    }, {})
                }
            }
            if (division === "home") {
                const { result } = await getDivisionHomeData({ fDate, eDate })
                return {
                    [dictionary[accesKey]]: result.reduce((accum, val) => {
                        const { Cantidad, MontoVenta } = val
                        return {
                            ...accum,
                            Cantidad: (accum["Cantidad"] || 0) + Cantidad,
                            Facturación: (accum["Facturación"] || 0) + MontoVenta
                        }
                    }, {})
                }
            }
            if (division === "spares") {
                const { result } = await getDivisionSparesData({ fDate, eDate })
                return {
                    [dictionary[accesKey]]: result.reduce((accum, val) => {
                        const { Cantidad, MontoVenta } = val
                        return {
                            ...accum,
                            Cantidad: (accum["Cantidad"] || 0) + Cantidad,
                            Facturación: (accum["Facturación"] || 0) + MontoVenta
                        }
                    }, {})
                }
            }
        }))

        if (division === "motorBikes") {
            dispatch(setCompareDataMotorBikes(datos))
        }
        if (division === "bikes") {
            dispatch(setCompareBikesData(datos))
        }
        if (division === "home") {
            dispatch(setCompareHomeData(datos))
        }
        if (division === "spares") {
            dispatch(setCompareSparesData(datos))
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Row justify="space-around" style={{ padding: pageWidth > 576 ? "40px" : 0 }}>
            {
                arrayData.map(val => {
                    const { Facturación, Cantidad } = val[Object.keys(val)[0]]
                    return <CardsElement
                        key={division + Object.keys(val)[0]}
                        title={Object.keys(val)[0]}
                        billing={Facturación}
                        quantity={Cantidad}
                    />
                })
            }
        </Row>
    )
}

export default ComparativeCards