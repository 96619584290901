import { actionsForDivisionHome } from './actions'
import { initialStateDivisionHome } from './initialState'

export const divisionHomeReducer = (state = initialStateDivisionHome, action) => {
    const { type, payload } = action
    switch (type) {
        case actionsForDivisionHome.SET_DIVISIONHOMEDATA:
            return {
                ...state,
                homeData: payload
            }
        case actionsForDivisionHome.SET_SELLERSDIVISIONHOME:
            return {
                ...state,
                homeSellersData: payload
            }
        case actionsForDivisionHome.SET_PRODUCTSDIVISIONHOME:
            return {
                ...state,
                homeProductsData: payload
            }
        case actionsForDivisionHome.SET_PRODUCTSDIVISIONHOMEBYDATE:
            return {
                ...state,
                homeProductsDataByDate: payload
            }
        case actionsForDivisionHome.SET_COMPAREHOMEDATA:
            return {
                ...state,
                compareHome: payload
            }
        default:
            return state
    }
}

export const setDivisionHomeData = content => ({
    type: actionsForDivisionHome.SET_DIVISIONHOMEDATA,
    payload: content
})

export const setSellersHomeData = content => ({
    type: actionsForDivisionHome.SET_SELLERSDIVISIONHOME,
    payload: content
})

export const setProductsHomeData = content => ({
    type: actionsForDivisionHome.SET_PRODUCTSDIVISIONHOME,
    payload: content
})

export const setProductsHomeByDateData = content => ({
    type: actionsForDivisionHome.SET_PRODUCTSDIVISIONHOMEBYDATE,
    payload: content
})

export const setCompareHomeData = content => ({
    type: actionsForDivisionHome.SET_COMPAREHOMEDATA,
    payload: content
})