import { actionsForDivisionSpares } from './actions'
import { initialStateDivisionSpares } from './initialState'

export const divisionSparesReducer = (state = initialStateDivisionSpares, action) => {
    const { type, payload } = action
    switch (type) {
        case actionsForDivisionSpares.SET_DIVISIONSPARESDATA:
            return {
                ...state,
                sparesData: payload
            }
        case actionsForDivisionSpares.SET_SELLERSDIVISIONSPARES:
            return {
                ...state,
                sparesSellersData: payload
            }
        case actionsForDivisionSpares.SET_PRODUCTSDIVISIONSPARES:
            return {
                ...state,
                sparesProductsData: payload
            }
        case actionsForDivisionSpares.SET_PRODUCTSDIVISIONSPARESBYDATE:
            return {
                ...state,
                sparesProductsDataByDate: payload
            }
        case actionsForDivisionSpares.SET_COMPARESPARESDATA:
            return {
                ...state,
                compareSpares: payload
            }

        default:
            return state
    }
}

export const setDivisionSparesData = content => ({
    type: actionsForDivisionSpares.SET_DIVISIONSPARESDATA,
    payload: content
})

export const setSellersSparesData = content => ({
    type: actionsForDivisionSpares.SET_SELLERSDIVISIONSPARES,
    payload: content
})

export const setProductsSparesData = content => ({
    type: actionsForDivisionSpares.SET_PRODUCTSDIVISIONSPARES,
    payload: content
})

export const setProducsSparesByDateData = content => ({
    type: actionsForDivisionSpares.SET_PRODUCTSDIVISIONSPARESBYDATE,
    payload: content
})

export const setCompareSparesData = content => ({
    type: actionsForDivisionSpares.SET_COMPARESPARESDATA,
    payload: content
})