import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LayoutComponent from './components/LayoutComponent'
import MotorBikes from './components/divisionMotorBikes'
import DivisionHome from './components/divisionHome'
import PaymenthsMethods from './components/paymenthsMethods'
import HomePage from './pages/HomePage'
import { ConfigProvider } from 'antd'
import esES from 'antd/lib/locale/es_ES'
import DivisionBikes from './components/divisionBikes'
import DivisionSpares from './components/divisionSpares'
import LoginPage from './pages/LoginPage'
import PrivateRoute from './utils/PrivateRoute'
import { auth } from './utils/auth'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData } from './redux/filterDatesAndUserReducer'
import SectionUnderBuild from './pages/SectionUnderBuild'

function App() {

  const userData = useSelector(state => state.user.userData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUserData({ isLogedIn: auth.isAuthenticated() }))
  }, [])

  useEffect(() => {
    (!auth.isAuthenticated() && window.location.pathname !== '/') && window.location.replace('/')
  }, [])

  return (
    <Router>
      <div style={{
        display: "none"
      }}>
        <a href="/division-mediospago/medios"></a>
        <a href="/division-mediospago/planes"></a>
        <a href="/division-repuestos/sucursales"></a>
        <a href="/division-repuestos/vendedores"></a>
        <a href="/division-repuestos/productos"></a>
        <a href="/division-bikes/sucursales"></a>
        <a href="/division-bikes/vendedores"></a>
        <a href="/division-bikes/productos"></a>
        <a href="/division-hogar/sucursal"></a>
        <a href="/division-hogar/vendedores"></a>
        <a href="/division-hogar/productos"></a>
        <a href="/division-motos/sucursal"></a>
        <a href="/division-motos/vendedores"></a>
        <a href="/division-motos/vehiculos"></a>
        <a href="/division-segumak/sucursal"></a>
        <a href="/division-segumak/vendedores"></a>
        <a href="/division-segumak/productos"></a>
        <a href="/inicio"></a>
        <a href="/"></a>
      </div>
      {
        userData?.isLogedIn ?
          <ConfigProvider locale={esES}>
            <Switch>
              <LayoutComponent>
                <PrivateRoute path="/division-mediospago/:section?" exact component={PaymenthsMethods} />
                <PrivateRoute path="/division-repuestos/:section?" exact component={DivisionSpares} />
                <PrivateRoute path="/division-bikes/:section?" exact component={DivisionBikes} />
                <PrivateRoute path="/division-hogar/:section?" exact component={DivisionHome} />
                <PrivateRoute path="/division-motos/:section?" exact component={MotorBikes} />
                <PrivateRoute path="/division-segumak/:section?" exact component={SectionUnderBuild} />
                <PrivateRoute path="/inicio" exact component={HomePage} />
              </LayoutComponent>
            </Switch>
          </ConfigProvider>
          :
          null
      }
      <Route path="/" exact component={LoginPage} />
    </Router>
  )
}

export default App
