import React from 'react'
import { Avatar, Button, Row } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import logoutHandler from './login/services/logout'


function AvatarComponent() {
    return (
        <div style={{ padding: '13px 22px' }}>
            <Row align="middle">
                <Avatar size={30} icon={<UserOutlined />} />
                <p style={{
                    color: "white",
                    marginBottom: "0",
                    marginLeft: "10px"
                }}>Wilo</p>
            </Row>
            <Row justify="end">
                <Button
                    type="primary"
                    size="small"
                    onClick={async () => await logoutHandler()}
                >
                    Salir
                </Button>
            </Row>
        </div>
    )
}

export default AvatarComponent