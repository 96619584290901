import { Modal, Button, Form } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDatesToFilter } from '../redux/filterDatesAndUserReducer'
import FormFilterDates from './FormFilterDates'

function ModalFilterDates() {

    const dispatch = useDispatch()
    const [modalVisible, setModalVisible] = useState()

    const [modalForm] = Form.useForm()

    const getValuesFormHandler = () => {
        modalForm.validateFields()
            .then(values => {
                dispatch(setDatesToFilter(values))
            })
    }

    return (
        <>
            <Button type="primary" onClick={() => setModalVisible(true)}>
                Filtrar
            </Button>
            <Modal
                title="Filtro de Fechas"
                centered
                visible={modalVisible}
                onOk={() => {
                    getValuesFormHandler()
                    setModalVisible(false)
                }}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >
                <FormFilterDates modalForm={modalForm} />
            </Modal>
        </>
    )
}

export default ModalFilterDates