import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDivisionSparesData } from '../../redux/divisionSparesReducer'
import { getDivisionSparesData } from '../divisionSpares/services/getDivisionSparesData'
import { errorMessage, openMessage, successfullyMessage } from '../Messages'

export function useInitDivisionSparesData({ data, fDate, eDate, division, date = undefined }) {

    const dispatch = useDispatch()
    const [lastFilterDates, setLastFilterDates] = useState({})

    const initDivisionSparesData = useCallback(async () => {
        openMessage({ message: "Cargando..." })
        const { result, message, error_msg } = await getDivisionSparesData({ fDate, eDate, division, date })
        if (message.includes('Successfully')) {
            successfullyMessage({ message: "Datos Cargados" })
            dispatch(setDivisionSparesData(result))
        } else if (!!error_msg) {
            errorMessage({ message: "Ocurrió un error" })
        }
    }, [dispatch, fDate, eDate, division, date])

    useEffect(() => {
        (async () => {
            if (data?.length > 0 && !!fDate && !!eDate) {
                if (fDate !== lastFilterDates?.fDate || eDate !== lastFilterDates?.eDate) {
                    initDivisionSparesData()
                    setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
                }
            } else if (data?.length === 0 && !!fDate && !!eDate) {
                initDivisionSparesData()
                setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
            }
        })()
    }, [data, fDate, eDate, division, date])

}