import { message as AlertMessage } from 'antd'

const key = 'updatable'

export const openMessage = ({ message = "Cargando..." }) => {
    AlertMessage.loading({ content: message, key, duration: 0 })
}

export const successfullyMessage = ({ message = "Datos Cargados" }) => {
    AlertMessage.success({ content: message, key, duration: 2 })
}

export const errorMessage = ({ message = "Ocurrió un error" }) => {
    AlertMessage.error({ content: message, key, duration: 2 })
}