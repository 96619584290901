import React, { useCallback, useEffect, useState } from 'react'
import { Line } from '@ant-design/charts'
import { useSelector } from 'react-redux'
import { Col, Row, Select, Spin, Empty } from 'antd'
import { useGetFilters } from '../hooks/useGetFilters'
import { monthsLargeDictionary } from '../../utils/monthsDictionary'

function ProductsDivisionHomeByDate() {

    const sparesProductsDataByDate = useSelector(state => state.spares.sparesProductsDataByDate)

    const currentDate = new Date()
    const [monthsToFilter, setMonthsToFilter] = useState(0)
    const [yearToFilter, setYearToFilter] = useState(0)
    const [GraphyData, setGraphyData] = useState([])

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const { fDate, eDate } = datesToFilter

    const { years, zones, months } = useGetFilters(sparesProductsDataByDate)

    useEffect(() => {
        setYearToFilter(years[0])
    }, [years, zones, months])

    const normalizeMotorBikesData = useCallback((data = [], year, month) => {
        const dataNormalized = [...data]
            .filter(dato => !!month ? (month === dato.MES && year === dato.ANIO) : year === dato.ANIO)
            .reduce((accum, val) => ({
                ...accum,
                [val.DocDate]: (accum?.[val.DocDate] || 0) + val.Cantidad
            }), {})

        setGraphyData(Object.keys(dataNormalized)
            .sort((a, b) => Date.parse(a) - Date.parse(b))
            .map(key => ({ date: key, Cantidad: dataNormalized[key] })))
    }, [yearToFilter])

    const config = {
        data: GraphyData,
        xField: 'date',
        yField: 'Cantidad',
        label: {},
        // point: {
        //     size: 5,
        //     shape: 'diamond',
        //     style: {
        //         fill: 'white',
        //         stroke: '#5B8FF9',
        //         lineWidth: 2,
        //     },
        // },
        tooltip: { showMarkers: false },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [{ type: 'marker-active' }],
    }

    useEffect(() => {
        if (sparesProductsDataByDate.length > 0) {
            normalizeMotorBikesData(sparesProductsDataByDate, yearToFilter ?? years[0], monthsToFilter !== 0 ? monthsToFilter : months[0])
        }
    }, [yearToFilter, monthsToFilter, sparesProductsDataByDate])

    return (
        <div>
            <div>
                <h3 style={{ textAlign: 'center' }}>
                    Venta diaria
                </h3>
                <Row justify="end" align="middle">
                    <Col xs={24} md={4} >
                        {
                            years?.length > 1 &&
                            <Select
                                size="small"
                                placeholder="Elegir Año"
                                onChange={e => setYearToFilter(e)}
                                defaultValue={years[0]}
                                style={{ width: '80%' }}
                            >
                                {
                                    years?.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)
                                }
                            </Select>
                        }
                    </Col>
                    {
                        months?.length > 1 &&
                        <Col xs={24} md={4} >
                            <Select
                                // mode="multiple"
                                size="small"
                                placeholder="Elegir Mes"
                                onChange={e => setMonthsToFilter(e)}
                                defaultValue={months[0]}
                                style={{ width: '100%' }}
                            >
                                {months?.filter(dato => dato).sort((a, b) => a - b).map(val => {
                                    console.log(currentDate.getFullYear() === yearToFilter && val <= (currentDate.getMonth() + 1), currentDate.getFullYear(), yearToFilter, val, (currentDate.getMonth() + 1))
                                    if (currentDate.getFullYear() === yearToFilter && val <= (currentDate.getMonth() + 1)) {
                                        return <Select.Option key={val} value={val}>{monthsLargeDictionary[val]}</Select.Option>
                                    } else if (currentDate.getFullYear() !== yearToFilter) {
                                        return <Select.Option key={val} value={val}>{monthsLargeDictionary[val]}</Select.Option>

                                    }
                                })
                                }
                            </Select>

                        </Col>
                    }
                </Row>
            </div>
            {
                GraphyData?.length > 0 ?
                    <Line {...config} />
                    :
                    <Row justify="center">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        {
                            GraphyData?.length === 0 && !!fDate && !!eDate &&
                            <Spin />
                        }
                    </Row>

            }
        </div>
    )
}

export default ProductsDivisionHomeByDate