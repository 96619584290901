import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setProductsBikesData } from '../../redux/divisionBikesReducer'
import { setProductsHomeData } from '../../redux/divisionHomeReducer'
import { setProductsSparesData } from '../../redux/divisionSparesReducer'
import { setProductsMotorBikesData } from '../../redux/divisionMotorBikesReducer'
import { getProductHomeData } from '../divisionHome/servicios/getProductsHomeData'
import { errorMessage, openMessage, successfullyMessage } from '../Messages'
import { getProductsMotorBikesData } from '../divisionMotorBikes/services/getPorductsMotorBikes'

export function useInitProductsData({ data, fDate, eDate, division, date = undefined }) {
    const dispatch = useDispatch()
    const [lastFilterDates, setLastFilterDates] = useState({})

    const initProductsData = useCallback(async () => {
        openMessage({ message: "Cargando..." })
        if (division === "motos") {
            const { result = [], message = "", error_msg = "" } = await getProductsMotorBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setProductsMotorBikesData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "bikes") {
            const { result = [], message = "", error_msg = "" } = await getProductsMotorBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setProductsBikesData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "hogar") {
            const { result = [], message = "", error_msg = "" } = await getProductHomeData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setProductsHomeData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "repuestos") {
            const { result = [], message = "", error_msg = "" } = await getProductsMotorBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setProductsSparesData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
    }, [dispatch, fDate, eDate, division, date])

    useEffect(() => {
        (async () => {
            if (data?.length > 0 && !!fDate && !!eDate) {
                if (fDate !== lastFilterDates?.fDate || eDate !== lastFilterDates?.eDate) {
                    initProductsData()
                    setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
                }
            } else if (data?.length === 0 && !!fDate && !!eDate) {
                initProductsData()
                setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
            }
        })()
    }, [initProductsData, data, fDate, eDate, division, date])

}