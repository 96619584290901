import { createStore, combineReducers } from 'redux'
import { motorBikesReducer } from './divisionMotorBikesReducer'
import { filterDatesAndUserReducer } from './filterDatesAndUserReducer'
import { paymenthsMethodsReducer } from './paymenthsMethodsReducer'
import { divisionHomeReducer } from './divisionHomeReducer'
import { divisionBikesReducer } from './divisionBikesReducer'
import { divisionSparesReducer } from './divisionSparesReducer'

export const store = createStore(combineReducers({
    motorBikes: motorBikesReducer,
    user: filterDatesAndUserReducer,
    paymenths: paymenthsMethodsReducer,
    home: divisionHomeReducer,
    bikes: divisionBikesReducer,
    spares: divisionSparesReducer
}))