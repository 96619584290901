let currentDate = new Date()
let yesterday = new Date()
let twoDaysBefore = new Date()
let firstDateCurrentMonth = new Date()
let firstDateLastMonth = new Date()
let lastDateLastMonth = new Date()
let firstDatetwoMonthBefore = new Date()
let lastDatetwoMonthBefore = new Date()
yesterday.setDate(yesterday.getDate() - 1)
twoDaysBefore.setDate(twoDaysBefore.getDate() - 2)

function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
}

if (currentDate.getDate() >= 30 || (currentDate.getMonth() == 2 && daysInMonth(currentDate.getMonth(), currentDate.getFullYear()) == 28)) {
    const lastDate = daysInMonth(currentDate.getMonth(), currentDate.getFullYear())
    lastDateLastMonth.setDate(lastDate)
    lastDatetwoMonthBefore.setDate(lastDate)
} else {
    lastDateLastMonth.setDate(currentDate.getDate())
    lastDatetwoMonthBefore.setDate(currentDate.getDate())
}

firstDateCurrentMonth.setDate(1)
firstDateLastMonth.setDate(1)
firstDateLastMonth.setMonth(currentDate.getMonth() - 1)
firstDatetwoMonthBefore.setDate(1)
firstDatetwoMonthBefore.setMonth(currentDate.getMonth() - 2)
lastDateLastMonth.setMonth(currentDate.getMonth() - 1)
lastDatetwoMonthBefore.setMonth(currentDate.getMonth() - 2)

export const filterDates = {
    today: {
        fDate: currentDate.toLocaleDateString(),
        eDate: currentDate.toLocaleDateString()
    },
    yesterday: {
        fDate: yesterday.toLocaleDateString(),
        eDate: yesterday.toLocaleDateString()
    },
    towDatesBefore: {
        fDate: twoDaysBefore.toLocaleDateString(),
        eDate: twoDaysBefore.toLocaleDateString()
    },
    currentMonth: {
        fDate: firstDateCurrentMonth.toLocaleDateString(),
        eDate: currentDate.toLocaleDateString()
    },
    lastMonth: {
        fDate: firstDateLastMonth.toLocaleDateString(),
        eDate: lastDateLastMonth.toLocaleDateString()
    },
    twoMonthsBefore: {
        fDate: firstDatetwoMonthBefore.toLocaleDateString(),
        eDate: lastDatetwoMonthBefore.toLocaleDateString()
    },
}