import React, { useCallback, useEffect, useState } from 'react'
import { DualAxes } from '@ant-design/charts'
import { useSelector } from 'react-redux'
import { monthsShortDictionary } from '../../utils/monthsDictionary'
import { Col, Row, Select } from 'antd'
import { useGetFilters } from '../hooks/useGetFilters'
import { config } from './BarsGraphycBrandOfficesConfig'

function BarsGraphycBrandOfficesBilling() {

    const motorBikesData = useSelector(state => state.motorBikes.motorBikesData)

    const [yearToFilter, setYearToFilter] = useState(0)
    const [zonesInData, setZonesInData] = useState([])

    const [graphycData, setGraphycData] = useState([])

    const { years, zones } = useGetFilters(motorBikesData)

    useEffect(() => {
        setYearToFilter(years[0])
    }, [years, zones])

    const normalizeMotorBikesData = useCallback((data = [], year, zone = []) => {
        return data
            .filter(dato => zone.length > 0 ? (zone.includes(dato.ZONA) && year === dato.ANIO) : year === dato.ANIO)
            .reduce((accum, val) => {
                const { ANIO, MES, MontoVenta, Cantidad } = val
                return {
                    ...accum,
                    [`${monthsShortDictionary[MES]}/${ANIO}`]: { Cantidad: (accum[`${monthsShortDictionary[MES]}/${ANIO}`]?.Cantidad || 0) + Cantidad, Facturación: (accum[`${monthsShortDictionary[MES]}/${ANIO}`]?.Facturación || 0) + MontoVenta, label: `${monthsShortDictionary[MES]}/${ANIO}`, order: `${ANIO}-${MES}` }
                }


            }, {})

    }, [yearToFilter])

    useEffect(() => {
        if (motorBikesData?.length > 0) {
            const data = normalizeMotorBikesData(motorBikesData, yearToFilter ?? years[0], zonesInData)
            if (!!data) {
                if (Object.keys(data)?.length > 0) {
                    setGraphycData(Object.keys(data)
                        .sort((a, b) => data[a].order.split('-')[0] - data[b].order.split('-')[0] || data[a].order.split('-')[1] - data[b].order.split('-')[1])
                        .map(key => data[key]))
                }
            }
        }
    }, [yearToFilter, zonesInData, motorBikesData])


    const graphycConfig = { ...config, data: [graphycData, graphycData] }

    return (
        <>
            <Row justify="end">
                <Col xs={24} md={4} >
                    {
                        years?.length > 1 &&
                        <Select
                            size="small"
                            placeholder="Elegir Año"
                            onChange={e => setYearToFilter(e)}
                            defaultValue={years[0]}
                            style={{ width: '80%' }}
                        >
                            {
                                years?.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)
                            }
                        </Select>
                    }
                </Col>
                <Col xs={24} md={8} >
                    {
                        zones?.length > 0 &&
                        <Select
                            mode="multiple"
                            size="small"
                            placeholder="Elegir Zonas"
                            // defaultValue={["Todas"]}
                            onChange={e => setZonesInData(e)}
                            style={{ width: '100%' }}
                        >
                            {zones?.filter(dato => dato).map(val => <Select.Option key={val} value={val}>{val}</Select.Option>)}
                        </Select>
                    }

                </Col>
            </Row>
            {graphycData.length > 0 &&
                <DualAxes {...graphycConfig} />
            }
        </>
    )
}

export default BarsGraphycBrandOfficesBilling