import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDivisionHomeData } from '../../redux/divisionHomeReducer'
import { getDivisionHomeData } from '../divisionHome/servicios/getDivisionHomeData'
import { errorMessage, openMessage, successfullyMessage } from '../Messages'

export function useInitDivisionHomeData({ data, fDate, eDate, division }) {

    const dispatch = useDispatch()
    const [lastFilterDates, setLastFilterDates] = useState({})

    const initDivisionHomeData = useCallback(async () => {
        openMessage({ message: "Cargando..." })
        const { result, message, error_msg } = await getDivisionHomeData({ fDate, eDate, division })
        if (message.includes('Successfully')) {
            successfullyMessage({ message: "Datos Cargados" })
            dispatch(setDivisionHomeData(result))
        } else if (!!error_msg) {
            errorMessage({ message: "Ocurrió un error" })
        }
    }, [dispatch, fDate, eDate, division])

    useEffect(() => {
        (async () => {
            if (data?.length > 0 && !!fDate && !!eDate) {
                if (fDate !== lastFilterDates?.fDate || eDate !== lastFilterDates?.eDate) {
                    initDivisionHomeData()
                    setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
                }
            } else if (data?.length === 0 && !!fDate && !!eDate) {
                initDivisionHomeData()
                setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
            }
        })()
    }, [data, fDate, eDate, division])

}