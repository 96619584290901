import { clientAxios } from '../../../config'

export const getPaymentsMethods = async ({ fDate, eDate, date, plan }) => {
    try {
        const resultGetMotorBikeData = await clientAxios.get('/paymenths.php', {
            params: {
                fDate: fDate,
                eDate: eDate,
                date: date,
                plan
            },
        })
        const { result, message = "", error_msg = "" } = resultGetMotorBikeData.data
        return { result, message, error_msg }

    } catch (error) {
        console.log(error)
    }
}