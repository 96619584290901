import { actionsForMotorBikes } from './actions'
import { initialStateMotorBikes } from './initialState'

export const motorBikesReducer = (state = initialStateMotorBikes, action) => {
    const { type, payload } = action
    switch (type) {
        case actionsForMotorBikes.SET_MOTORBIKESDATA:
            return {
                ...state,
                motorBikesData: payload
            }
        case actionsForMotorBikes.SET_SELLERSMOTORBIKES:
            return {
                ...state,
                motorBikesSellerData: payload
            }
        case actionsForMotorBikes.SET_ZONESMOTORBIKESDATA:
            return {
                ...state,
                motorBikesZonesData: payload
            }
        case actionsForMotorBikes.SET_PRODUCTSMOTORBIKESDATA:
            return {
                ...state,
                motorBikesProductsData: payload
            }
        case actionsForMotorBikes.SET_PRODUCTSMOTORBIKESDATABYDATE:
            return {
                ...state,
                motorBikesProductsDataByDate: payload
            }
        case actionsForMotorBikes.SET_YEARSINDATA:
            return {
                ...state,
                yearsInData: payload
            }
        case actionsForMotorBikes.SET_COMPAREDATA:
            return {
                ...state,
                compareMotorBikes: payload
            }
        default:
            return state
    }
}

export const setMotorBikesData = content => ({
    type: actionsForMotorBikes.SET_MOTORBIKESDATA,
    payload: content
})

export const setSellersData = content => ({
    type: actionsForMotorBikes.SET_SELLERSMOTORBIKES,
    payload: content
})

export const setZoneMotorBikesData = content => ({
    type: actionsForMotorBikes.SET_ZONESMOTORBIKESDATA,
    payload: content
})

export const setProductsMotorBikesData = content => ({
    type: actionsForMotorBikes.SET_PRODUCTSMOTORBIKESDATA,
    payload: content
})

export const setProductsMotorBikesDataByDate = content => ({
    type: actionsForMotorBikes.SET_PRODUCTSMOTORBIKESDATABYDATE,
    payload: content
})

export const setYearsInData = content => ({
    type: actionsForMotorBikes.SET_YEARSINDATA,
    payload: content
})

export const setCompareDataMotorBikes = content => ({
    type: actionsForMotorBikes.SET_COMPAREDATA,
    payload: content
})