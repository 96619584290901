export const initialStateMotorBikes = {
    motorBikesData: [],
    motorBikesSellerData: [],
    motorBikesZonesData: [],
    motorBikesProductsData: [],
    motorBikesProductsDataByDate: [],
    yearsInData: [],
    compareMotorBikes: []
}

export const initialStateDivisionBikes = {
    bikesData: [],
    bikesSellersData: [],
    bikesProductsData: [],
    bikesProductsDataByDate: [],
    compareBikes: []
}

export const initialStateUser = {
    userData: {},
    datesToFilter: {}
}

export const initialStatePaymenthsMethods = {
    paymenthsMethods: [],
    plansData: []
}

export const initialStateDivisionHome = {
    homeData: [],
    homeSellersData: [],
    homeProductsData: [],
    homeProductsDataByDate: [],
    compareHome: []
}

export const initialStateDivisionSpares = {
    sparesData: [],
    sparesSellersData: [],
    sparesProductsData: [],
    sparesProductsDataByDate: [],
    compareSpares: []
}
