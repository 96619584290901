import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Select, Table } from 'antd'
import { monthsLargeDictionary, monthsLargeNumberDictionary } from '../../utils/monthsDictionary'
import { useGetFilters } from '../hooks/useGetFilters'

function ProductsDivisionHomeTable() {

    const sparesProductsData = useSelector(state => state.spares.sparesProductsData)

    const [zonesToFilter, setZonesToFilter] = useState([])
    const [yearToFilter, setYearToFilter] = useState(0)
    const [tableData, setTableData] = useState({
        columns: [],
        rows: []
    })

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    const { fDate, eDate } = datesToFilter

    const tableDictionary = {
        ItemName: 'Producto',
        total: 'Total'
    }

    const { years, zones, months } = useGetFilters(sparesProductsData)

    useEffect(() => {
        setYearToFilter(years[0])
    }, [years, zones, months])

    const normalizeData = useCallback((data = [], year, zones = []) => {
        console.log(`normalizeData ~ data`, data)

        if (data.length > 0) {
            const reduceFilteredData = [...data]
                .filter(dato => zones.length > 0 ? (zones.includes(dato.ZONA) && year === dato.ANIO) : year === dato.ANIO)
                .reduce((accum, val) => {
                    const { MES, Cantidad, ItemCode, ItemName } = val

                    return {
                        ...accum,
                        [ItemCode]: {
                            ItemName: ItemName,
                            ...accum?.[ItemCode],
                            [monthsLargeDictionary[MES]]: (accum?.[ItemCode]?.[monthsLargeDictionary[MES]] || 0) + Cantidad,
                            total: (accum?.[ItemCode]?.total || 0) + Cantidad
                        }
                    }
                }, {})

            if (!!reduceFilteredData) {
                console.log(`normalizeData ~ reduceFilteredData`, reduceFilteredData)
                const orderedData = Object.keys(reduceFilteredData).sort((a, b) => Object.keys(reduceFilteredData[b]).length - Object.keys(reduceFilteredData[a]).length)
                if (Object.keys(reduceFilteredData).length > 0) {
                    const columns = Object.keys(reduceFilteredData[orderedData[0]]).map(col => {
                        if (!col.includes("number-")) return {
                            title: tableDictionary[col] ?? col,
                            dataIndex: col,
                            key: col,
                            align: tableDictionary[col] === 'Producto' ? 'left' : 'center',
                            ellipsis: {
                                showTitle: true,
                            },
                            width: tableDictionary[col] === 'Producto' ? "25%" : 'default',
                            order: col === 'ItemName' ? -1 : col === 'total' ? 14 : monthsLargeNumberDictionary[col],
                            sorter: col === 'ItemName' ? (a, b) => a[col].localeCompare(b[col]) : (a, b) => b[col] - a[col],

                        }
                    }).sort((a, b) => a.order - b.order).filter(col => col)

                    const rows = Object.keys(reduceFilteredData).map(key => reduceFilteredData[key]).sort((a, b) => b.total - a.total)

                    setTableData(tableData => ({ ...tableData, columns: columns, rows: rows }))
                }
            }
        }

    }, [yearToFilter, zonesToFilter])

    useEffect(() => {
        if (sparesProductsData.length > 0) {
            normalizeData(sparesProductsData, yearToFilter ?? years[0], zonesToFilter)
        }

    }, [yearToFilter, zonesToFilter, sparesProductsData])

    return (
        <>
            <Table
                scroll={{ y: 320, x: true }}
                size="small"
                loading={tableData?.rows?.length === 0 && !!fDate && !!eDate}
                title={() =>
                    <div>
                        <h3 style={{ textAlign: 'center' }}>
                            Cantidad de unidades por Producto
                        </h3>
                        <Row justify="end" align="middle">
                            <Col xs={24} md={4} >
                                {
                                    years?.length > 1 &&
                                    <Select
                                        size="small"
                                        placeholder="Elegir Año"
                                        onChange={e => setYearToFilter(e)}
                                        defaultValue={years[0]}
                                        style={{ width: '80%' }}
                                    >
                                        {
                                            years?.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)
                                        }
                                    </Select>
                                }
                            </Col>
                            {
                                zones?.length > 1 &&
                                <Col xs={24} md={8} >
                                    <Select
                                        mode="multiple"
                                        size="small"
                                        placeholder="Elegir Zonas"
                                        // defaultValue={["Todas"]}
                                        onChange={e => setZonesToFilter(e)}
                                        style={{ width: '100%' }}
                                    >
                                        {zones?.filter(dato => dato).map(val => <Select.Option key={val} value={val}>{val}</Select.Option>)}
                                    </Select>

                                </Col>
                            }
                        </Row>
                    </div>
                }
                pagination={{ position: ["bottomCenter"] }}
                columns={tableData.columns}
                dataSource={tableData.rows}
            />
        </>
    )
}

export default ProductsDivisionHomeTable