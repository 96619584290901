import { clientAxios } from '../../../config'

export const getProductsMotorBikesData = async ({ fDate, eDate, division, date }) => {
    try {
        const resultGetMotorBikeData = await clientAxios.get('/products.php', {
            params: {
                fDate: fDate,
                eDate: eDate,
                division: division,
                date: date
            },
        })
        const { result, message = "", error_msg = "" } = resultGetMotorBikeData.data
        return { result, message, error_msg }

    } catch (error) {
        if ("response" in error) {
            const { response } = error
            const { data } = response
            const { result = [], message = "", error_msg = "" } = data
            return { result, message, error_msg }
        } else {
            const result = [], message = "An error has ocurred", error_msg = ""
            return { result, message, error_msg }
        }
    }
}