import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setSellersBikesData } from '../../redux/divisionBikesReducer'
import { setSellersHomeData } from '../../redux/divisionHomeReducer'
import { setSellersSparesData } from '../../redux/divisionSparesReducer'
import { setSellersData } from '../../redux/divisionMotorBikesReducer'
import { getSellersBikesData } from '../divisionBikes/services/getSellersBikesData'
import { getSellersHomeData } from '../divisionHome/servicios/getSellersHomeData'
import { getSellersSparesData } from '../divisionSpares/services/getSellersSparesData'
import { errorMessage, openMessage, successfullyMessage } from '../Messages'
import { getSellersMotorBikesData } from '../divisionMotorBikes/services/getSellersData'

export function useInitSellersData({ data, fDate, eDate, division, date = undefined }) {

    const dispatch = useDispatch()
    const [lastFilterDates, setLastFilterDates] = useState({})

    const initSellerMotorBikeData = useCallback(async () => {
        openMessage({ message: "Cargando..." })
        if (division === "motos") {
            const { result, message, error_msg } = await getSellersMotorBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setSellersData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "bikes") {
            const { result, message, error_msg } = await getSellersBikesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setSellersBikesData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "hogar") {
            const { result, message, error_msg } = await getSellersHomeData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setSellersHomeData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
        if (division === "repuestos") {
            const { result, message, error_msg } = await getSellersSparesData({ fDate, eDate, division, date })
            if (message.includes('Successfully')) {
                successfullyMessage({ message: "Datos Cargados" })
                dispatch(setSellersSparesData(result))
            } else if (!!error_msg) {
                errorMessage({ message: "Ocurrió un error" })
            }
        }
    }, [dispatch, fDate, eDate, division, date])

    useEffect(() => {
        if (data?.length > 0 && !!fDate && !!eDate) {
            if (fDate !== lastFilterDates?.fDate || eDate !== lastFilterDates?.eDate) {
                initSellerMotorBikeData()
                setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
            }
        } else if (data?.length === 0 && !!fDate && !!eDate) {
            initSellerMotorBikeData()
            setLastFilterDates({ ...lastFilterDates, fDate: fDate, eDate: eDate })
        }
    }, [initSellerMotorBikeData, data, fDate, eDate, division, date])

}