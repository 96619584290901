import React, { useEffect } from 'react'
import { Layout, Menu } from 'antd'
import { BarChartOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { auth } from '../utils/auth'
import AvatarComponent from './AvatarComponent'


function SidebarComponent({ collapsed }) {


    const userData = useSelector(state => state.user.userData)

    const { Sider } = Layout
    const { SubMenu } = Menu
    const history = useHistory()

    useEffect(() => {
        (auth.isAuthenticated() && history.location.pathname === '/') && history.replace('/inicio')
    }, [userData?.isLogedIn])

    return (
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <AvatarComponent />
            <Menu theme="dark"
                mode="inline"
                defaultSelectedKeys={[history.location.pathname.split('/')[2] + '-' + history.location.pathname.split('/')[1].split('-')[1]]}
                defaultOpenKeys={[history.location.pathname.split('/')[1]]}
            >
                <Menu.Item key="/inicio" onClick={() => history.push('/inicio')} icon={<BarChartOutlined />}>
                    Resumen Mensual
                </Menu.Item>
                <SubMenu key="division-motos" icon={<BarChartOutlined />} title="División Motos">
                    <Menu.Item key="sucursales-motos" onClick={() => history.replace('/division-motos/sucursales')}>
                        Sucursales
                    </Menu.Item>
                    <Menu.Item key="vendedores-motos" onClick={() => history.replace('/division-motos/vendedores')}>
                        Vendedores
                    </Menu.Item>
                    <Menu.Item key="vehículos-motos" onClick={() => history.replace('/division-motos/vehiculos')}>
                        Vehículos
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="division-hogar" icon={<BarChartOutlined />} title="División Hogar">
                    <Menu.Item key="sucursales-hogar" onClick={() => history.replace('/division-hogar/sucursales')}>
                        Sucursales
                    </Menu.Item>
                    <Menu.Item key="vendedores-hogar" onClick={() => history.replace('/division-hogar/vendedores')}>
                        Vendedores
                    </Menu.Item>
                    <Menu.Item key="productos-hogar" onClick={() => history.replace('/division-hogar/productos')}>
                        Productos
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="division-bikes" icon={<BarChartOutlined />} title="División Bikes">
                    <Menu.Item key="sucursales-bikes" onClick={() => history.replace('/division-bikes/sucursales')}>
                        Sucursales
                    </Menu.Item>
                    <Menu.Item key="vendedores-bikes" onClick={() => history.replace('/division-bikes/vendedores')}>
                        Vendedores
                    </Menu.Item>
                    <Menu.Item key="productos-bikes" onClick={() => history.replace('/division-bikes/productos')}>
                        Productos
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="division-repuestos" icon={<BarChartOutlined />} title="División Repuestos">
                    <Menu.Item key="sucursales-repuestos" onClick={() => history.replace('/division-repuestos/sucursales')}>
                        Sucursales
                    </Menu.Item>
                    <Menu.Item key="vendedores-repuestos" onClick={() => history.replace('/division-repuestos/vendedores')}>
                        Vendedores
                    </Menu.Item>
                    <Menu.Item key="productos-repuestos" onClick={() => history.replace('/division-repuestos/productos')}>
                        Productos
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="division-mediospago" icon={<BarChartOutlined />} title="Medios de Pago">
                    <Menu.Item key="medios-mediospago" onClick={() => history.replace('/division-mediospago/medios')}>
                        Medios
                    </Menu.Item>
                    <Menu.Item key="planes-mediospago" onClick={() => history.replace('/division-mediospago/planes')}>
                        Planes
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="division-segumak" icon={<BarChartOutlined />} title="Segumak">
                    <Menu.Item key="sucursales-segumak" onClick={() => history.replace('/division-segumak/sucursales')}>
                        Sucursales
                    </Menu.Item>
                    <Menu.Item key="vendedores-segumak" onClick={() => history.replace('/division-segumak/vendedores')}>
                        Vehículos
                    </Menu.Item>
                    <Menu.Item key="vehículos-segumak" onClick={() => history.replace('/division-segumak/productos')}>
                        Vehículos
                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    )
}

export default SidebarComponent