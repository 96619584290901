import axios from 'axios'
import { auth } from './utils/auth'

axios.defaults.withCredentials = true

export const clientAxios = axios.create({
    baseURL: process.env.REACT_APP_ENVIROMENT_STATE === "developing" ? process.env.REACT_APP_BASE_URL_DEVELOPING : process.env.REACT_APP_BASE_URL_PRODUCCTION
})

clientAxios.defaults.withCredentials = true

clientAxios.interceptors.request.use((config) => {
    if (auth.isAuthenticated()) {
        config.withCredentials = true
    }
    return config
})

clientAxios.interceptors.response.use(response => {
    return response
}, error => {
    if ("response" in error) {
        const { response } = error
        const { status, data } = response
        if (status === 401 && data?.error_msg?.message?.value == "Invalid session.") {
            console.log('cerrar sesión')
            auth.logOut()
        }

    }
})