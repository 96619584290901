import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Row, Col } from 'antd'
import loginHandler from './services/login'
import { setUserData } from '../../redux/filterDatesAndUserReducer'
import { useDispatch } from 'react-redux'


function LoginComponent() {


    const pageWidth = window.screen.width
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const onFinish = async (values) => {
        const { UserName, Password } = values
        setLoading(true)
        const { result = "", message = "", error_msg = "" } = await loginHandler({ UserName, Password })
        setLoading(false)
        if (message.includes("Successfully")) {
            result.split(";").forEach(element => {
                document.cookie = element
            })
            dispatch(setUserData({ isLogedIn: true, UserName }))
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Row>
            <Col span={24}>
                <h1 style={{
                    textAlign: "center"
                }}>Tablero Gerencial</h1>
                <Form
                    layout="vertical"
                    style={{
                        width: pageWidth > 576 ? "30vw" : "80vw",
                        backgroundColor: "white",
                        padding: "30px",
                        borderRadius: "10px",
                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Usuario"
                        name="UserName"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, ingrese un usuario',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Contraseña"
                        name="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, ingrese una contraseña válida',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Row justify="center" style={{ width: "100%" }}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Ingresar
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Col>

        </Row>
    )
}

export default LoginComponent