import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BrandOfficesByBillingTable from './BrandsOfficesByBillingTable'
import BrandOfficesByQuantityTable from './BrandsOfficesByQuantityTable'
import { useInitDivisionHomeData } from './../hooks/useInitDivisionHomeData'
import SellersByBillingTable from './SellersByBillingTable'
import SellersByQuantityTable from './SellersByQuantityTable'
import { useInitProductsData } from '../hooks/useInitProductsData'
import { useInitSellersData } from '../hooks/useInitSellerData'
import ProductsDivisionHomeByDate from './ProductsDivisionHomeByDate'
import ProductsDivisionHomeTable from './ProductsDivisionHome'
import { useInitProductsByDate } from '../hooks/useInitProductByDate'
import { Col, Divider } from 'antd'

function DivisionHome() {

    const params = useParams()

    const sucursales = useRef(null)
    const productos = useRef(null)
    const vendedores = useRef(null)

    const division = "hogar"

    const homeData = useSelector(state => state.home.homeData)
    const homeSellersData = useSelector(state => state.home.homeSellersData)
    const homeProductsData = useSelector(state => state.home.homeProductsData)
    const homeProductsDataByDate = useSelector(state => state.home.homeProductsDataByDate)

    const datesToFilter = useSelector(state => state.user.datesToFilter)

    useInitDivisionHomeData({ data: homeData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate })
    useInitProductsByDate({ data: homeProductsDataByDate, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division, date: true })
    useInitProductsData({ data: homeProductsData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division })
    useInitSellersData({ data: homeSellersData, fDate: datesToFilter?.fDate, eDate: datesToFilter?.eDate, division })

    useEffect(() => {
        if (params.section === 'sucursales') {
            sucursales?.current?.scrollIntoView({ behavior: "smooth" })
        }
        if (params.section === 'productos') {
            productos?.current?.scrollIntoView({ behavior: "smooth" })
        }
        if (params.section === 'vendedores') {
            vendedores?.current?.scrollIntoView({ behavior: "smooth" })
        }


    }, [params.section])


    return (
        <>
            <Col span={24}>
                <h2 ref={sucursales} >Sucursales</h2>
                <BrandOfficesByBillingTable />
                <BrandOfficesByQuantityTable />
            </Col>
            <Divider />
            <Col span={24}>
                <h2 ref={vendedores}>Vendedores</h2>
                <SellersByBillingTable />
                <SellersByQuantityTable />
            </Col>
            <Divider />
            <Col span={24}>
                <h2 ref={productos}>Productos</h2>
                <ProductsDivisionHomeTable />
                <ProductsDivisionHomeByDate />
            </Col>
        </>
    )
}

export default DivisionHome