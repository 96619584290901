import { clientAxios } from "../../../config"
import { auth } from "../../../utils/auth"

async function logoutHandler() {
    try {
        const logoutResult = await clientAxios.post('/login.php')
        const { message = "", msg_error = "" } = logoutResult.data
        if (message.includes("Successfully")) {
            auth.logOut()
        }
        return { message, msg_error }
    } catch (error) {
        if ("response" in error) {
            const { response } = error
            const { data } = response
            const { message = "", error_msg = "" } = data
            return { message, error_msg }
        } else {
            const message = "An error has ocurred", error_msg = ""
            return { message, error_msg }
        }
    }
}

export default logoutHandler