import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setPlansData } from '../../redux/paymenthsMethodsReducer'
import { errorMessage, openMessage, successfullyMessage } from '../Messages'
import { getPaymentsMethods } from '../paymenthsMethods/services/getPaymenths'

function useInitPlansData({ data, fDate, eDate, date, plan }) {

    const dispatch = useDispatch()
    const [lastFilterDates, setLastFilterDates] = useState({})

    const initPlansData = useCallback(async () => {
        openMessage({ message: "Cargando..." })
        const { result, message, error_msg } = await getPaymentsMethods({ fDate, eDate, date, plan })
        if (message.includes('Successfully')) {
            successfullyMessage({ message: "Datos Cargados" })
            dispatch(setPlansData(result))
        } else if (!!error_msg) {
            errorMessage({ message: "Ocurrió un error" })
        }
    }, [dispatch, fDate, eDate, date])

    useEffect(() => {
        (async () => {
            if (data?.length > 0 && !!fDate && !!eDate) {
                if (fDate != lastFilterDates?.fDate || eDate != lastFilterDates?.eDate) {
                    initPlansData()
                    setLastFilterDates({ fDate: fDate, eDate: eDate })
                }
            } else if (data?.length === 0 && !!fDate && !!eDate) {
                initPlansData()
                setLastFilterDates({ fDate: fDate, eDate: eDate })
            }
        })()
    }, [initPlansData, data, fDate, eDate, date])
}

export default useInitPlansData