import { actionsForUser } from './actions'
import { initialStateUser } from './initialState'

export const filterDatesAndUserReducer = (state = initialStateUser, action) => {
    const { type, payload } = action
    switch (type) {
        case actionsForUser.SET_USERDATA:
            return {
                ...state,
                userData: payload
            }
        case actionsForUser.SET_FILTERDATES:
            return {
                ...state,
                datesToFilter: payload
            }
        default:
            return state
    }
}

export const setUserData = content => ({
    type: actionsForUser.SET_USERDATA,
    payload: content
})

export const setDatesToFilter = ({ fDate, eDate }) => ({
    type: actionsForUser.SET_FILTERDATES,
    payload: { fDate, eDate }
})