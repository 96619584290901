import { actionsForPaymenthsMethods } from './actions'
import { initialStatePaymenthsMethods } from './initialState'

export const paymenthsMethodsReducer = (state = initialStatePaymenthsMethods, action) => {
    const { type, payload } = action
    switch (type) {
        case actionsForPaymenthsMethods.SET_PAYMENTHSMETHODS:
            return {
                ...state,
                paymenthsMethods: payload
            }
        case actionsForPaymenthsMethods.SET_PLANSDATA:
            return {
                ...state,
                plansData: payload
            }
        default:
            return state
    }
}

export const setPaymenthsMethods = content => ({
    type: actionsForPaymenthsMethods.SET_PAYMENTHSMETHODS,
    payload: content
})

export const setPlansData = content => ({
    type: actionsForPaymenthsMethods.SET_PLANSDATA,
    payload: content
})